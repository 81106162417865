@import "../../style.scss";

.eventsPage{
    @include themify($themes) {
        width: 60vw;
        height: 100vh;
        border: none;
        background-color: themed('bgSoft');
        // background-color: red;
        color: themed('textColor');
        padding: 20px;
        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            select{
                font-size: 16px;
            }
            button{
                margin-top: 20px;
                padding: 10px 20px;
                background-color: themed("primary-color");
                color: themed("bg");
                border: none;
                border-radius: 5px;
                cursor: pointer;
                
                a{
                    color: themed("bg");
                    text-decoration: none;
                    font-size: 1rem;
                    font-weight: 700;
                }
            }
        }
        .events{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            place-items: center;
            margin-top: 20px;

            .comp-single{
                box-shadow: 0px 0px 5px lightgray;
                border-radius: 10px;
                // height:max-content;  
                // margin: 5px;
                height: 400px;
                margin: 5px;
                width: 300px;
                overflow-x: hidden;
                img{
                    border-radius: 10px 10px 0 0;
                }
                .details{
                    padding: 10px;
                }
            }

        }
}
}