@import "../../style.scss";

.eventsPage{
    @include themify($themes) {
        width: 100vw;
        height: 100vh;
        border: none;
        // background-color: themed('bgSoft');
        background-color: red;
        color: themed('textColor');
        padding: 20px;
        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            width: 100%;
        }
        .events{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            place-items: center;
            margin-top: 20px;

            .event{
                box-shadow: 0px 0px 5px lightgray;
                border-radius: 10px;
                height:max-content;  
            }

        }
        @include mobile{
            width: 100%;
        }
}
}