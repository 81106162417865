@import "../../style.scss";

.createGroups{
    @include themify($themes) {
        background-color: themed("bg");
        color: themed("textColor");
        height: calc(100vh - 50px); 
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;

        h1{
            margin-top: 50px
        }

        .formGroups{
            margin: 100px;
            width: 500px;
            // width: 75vw;
            padding: 20px;
            border-radius: 10px;
            background-color: themed("bgSoft");
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            justify-content: center;

            @include mobile{
                margin: 0px;
                width: 75vw;
            }

            .fileInput{
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                label{
                    text-align: left;
                    font-size: 0.8rem;
                    cursor: pointer;
                }
                .file {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 0px;
                  }
            }

            .list{
                width: 100%;
                height: 50%;
                overflow-y: scroll;

                &::-webkit-scrollbar{
                    display: none;
                }

                .friend{
                    display: flex;
                    justify-content: space-between;
                    padding: 5px;
                    background-color: themed('bg');
                    margin: 10px;
                    border-radius: 5px;
                    box-shadow: 0px 5px 15px themed("border");
                    p{
                        font-size: 0.9rem;
                        font-weight: 700;
                    }
                }
            }


                    .inp{
                        border: 1px solid themed("border");
                        border-radius: 5px;
                        outline: none;
                        padding: 5px;
                        font-size: 1rem;
                        font-weight: 600;
                        width: 100%;
                        background-color: themed("bgSoft");
                        color: themed("textColor");
                        @include mobile{
                            width: 75%;
                            padding: 20px;
                        }
                    }
                   
                    

            button{
                margin-top: 20px;
                padding: 10px 20px;
                background-color: themed("primary-color");
                color: black;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-weight: 700;
            }    
        }
    }
}