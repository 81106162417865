.viewer-main-container {
    .heading {
        background-color: #d8ae7e;
        font-weight: 600;
        color: white;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0px 10px;
        }

        .more-icon {
            margin-right: 5px;
            cursor: pointer;
        }
        
        .options-menu {
            position: absolute;
            top: 2.5rem;
            right: 0;
            background-color: white;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            width: 150px;
            z-index: 10;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    padding: 10px;
                    cursor: pointer;
                    &:hover {
                        background-color: #f0f0f0;
                    }
                    color : black;
                }
            }
        }
    }

    .viewer-container {
        margin: 10px 15px;
        display: flex;
        align-items: center;

        .viewer-img {
            margin-right: 15px;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .viewer-info {
            .viewer-name {
                font-weight: bold;
                font-size: 0.8rem;
            }

            .viewed-time {
                font-size: 0.6rem;
                color: gray;
            }
        }

    }
}