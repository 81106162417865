@import "../../style.scss";

.card {
  @include themify($themes) {
    height: 18rem;
    margin: 20px;
    cursor: pointer;
    box-shadow: 0px 5px 15px themed("border");
    border-radius: 10px;
    width: 300px;
    @include mobile{
      height: 22rem;
    }

    
    
    img{
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }

    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3{
        color: themed("text-color");
      }
      .button-upg{
        background-color: themed("primary-color");
        color: themed("text-color");
        padding: 5px;
      }
    }


    a{
        color: themed("textColor");
        text-decoration: none;
        font-size: 1rem;
        font-weight: 700;
    }

    p{
        margin-top: 10px;
        color: black;
        background-color: themed("primary-color");
        font-size: 0.7rem;
        width: 50% !important;
        // padding-left: 10px;
        border-radius: 10px;
        text-align: center;
        margin-left: 10px;
        @include mobile{
          font-size: 0.5rem;
        }
    }
    h6{
        margin-top: 10px;
        margin-left: 10px;
    }
  }
}
