
.userAndPostsData{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px 0px;
  
   
    .d-info-item{
        padding: 15px;
        width: 30%;
        // max-width: 370px;
        height: 300px;
        // min-width: 200px;
        border-radius: 50%;
        box-shadow: rgba(126, 118, 118, 0.205) 0px 7px 29px 0px;
        background-color: #deebfb;
        
        .d-info-title{
            font-size: 1.2em;
            font-weight: 600;
            line-height: 0%;
            padding-top: 8px;
            padding-top: 10px;
            align-items: center;
            margin-top: 45px;
            display: flex;
            flex-direction: column;
        }

        .d-info-content{
            display: flex;
            // align-items: center;
            margin-top: 85px;
            justify-content: center;
            text-align: center;
            height: 100%;

            span {
                color: red;
                // font-size: 1.8em;
                font-weight: 600;
            }
        }
    }
}

