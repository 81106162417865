@import "../../style.scss";

.rightBar {
  @include themify($themes) {
    flex: 2.7 1 0%;
    max-width: 450px;
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: scroll;
    background-color: themed("bgSoft");
    font-size: smaller;
&.hidden {
  display: none;
}
    @include mobile{
      display: none;
    }
    @include tablet{
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .admin-ads{
      // height: 20rem;
      // max-height: 15rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:start;
      gap: 10px;
      
      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      
    }

    .container {
      padding: 10px;
      
      .item {
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        padding: 20px;
        margin-bottom: 20px;
        background-color: themed("bg");

        h3{
          color: themed("textColor");
        }

        .star{
          margin-left: 10px;
          filter: brightness(250%);
          font-size: 1.5rem;
          animation: star 1s ease-in-out 1s infinite forwards;
        }
        @keyframes star {
          0%,100%{
            color:red
          }
          25%{
            color: rgb(230, 78, 18)
          }
          50%{
            color: rgb(240, 38, 72)
          }
          75%{
            color: rgb(199, 90, 17)
          }
        }

        .ads{
          width: 100%;
          height: 100%;
          gap: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px;

          img{
            width: 100%;
            height: 80%;
            object-fit: cover;
          }

          .carFooter{
            margin-top: 5px;
            width: 100%;
            height: 10%;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            color: themed("textColor");


            button{
              background-color: themed("primary-color");
              color: themed('text-color');
              padding:7px 20px;
              border-radius: 5px;
              cursor: pointer;
              border:none;
              font-weight: 700;
            }
          }
        }

        span {
          color: gray;
        }

        .hr {
          margin: 10px 0px;
          border: none;
          height: 0.5px;
          background-color: themed("border");
        }

        .user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0px;

          .userInfo {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            width: 100%;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }

            p{
              width: 60%;
            }

            .time{
              width: 30%;
              align-self: start;
            }

            .online {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: limegreen;
              position: absolute;
              top: 0;
              left: 30px;
            }

            p {
              color: themed("textColorSoft");
            }

            span {
              font-weight: 500;
              color: themed("textColor");
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
              border: none;
              padding: 5px;
              color: white;
              cursor: pointer;

              &:first-child {
                background-color: #5271ff;
              }

              &:last-child {
                background-color: #f0544f;
              }
            }
          }
        }
      }
    }
  }
  &.messagePage{
    display: none;
  }
}
