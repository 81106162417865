@import "../../style.scss";

.register {

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    display: flex;
    flex:1;
    flex-direction: row-reverse;
    background-color: white;
    height: 100vh;
    min-height: 600px;
    max-height: 1100px;
    max-width: 2000px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background:#387478;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295)),
        url("../../assets/demo22.jpg") center;
      background-size: cover;
    

    // .left{
    //   flex: 1;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    //   // background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295));
    //   background:#012e6b;
    //   overflow: hidden;

    //   h1{
    //     font-size: 2rem;
    //     font-weight: 700;
    //     color: white;
    //   }
      
    //   .outer{
    //     width: 75%;
    //     height: 75%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     background-color: #10386d;
    //     border-radius: 50%;
    //     animation: bounceO 5s infinite ease-in-out;
    //     overflow: hidden;
    //   }
    //   .middle{
    //     width: 75%;
    //     height: 75%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     background-color: #193e6e;
    //     border-radius: 50%;
    //     animation: bounceM 5s infinite ease-in-out;
    //   }
    //   .inner{
    //     width: 75%;
    //     height: 75%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     background-color: #1e416f;
    //     border-radius: 50%;
    //     animation: bounceI 5s infinite ease-in-out;
    //   }

    //   .image{
    //     flex: 1;
    //     // object-fit: contain;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 50%;
    //     // padding: 20px;
    //     background:#f3e9d4 url("../../assets/image_2.jpeg") center no-repeat;
    //     background-size: contain;
    //   }

     
    // }

    .right {
      // flex: 1;
      width: 40%;
      border-radius: 20px;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      justify-content: center;
      background-color: transparent;
      backdrop-filter: blur(250px);
      @include mobile{
        width: 100%;
      }

      .head{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          color: white;
          font-size: xx-large;
          margin-bottom: 2%;
          font-size: 3rem;
        }
        p{
          color: #fff;
          font-weight:700;
          font-size: 1.5rem;
          text-align: center;
        }
      } 
      h1 {
      color: #555;
      font-size: xx-large;
      }
      
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        input {
          // border: none;
          border: 1px solid lightgray;
          border-radius: 5px;
          padding: 10px 10px;
          font-size: medium;
          width: 90%;
          background-color: transparent;
          // color: rgb(172, 166, 166);
        }

        input::placeholder{
          color: black;
        }

        input:focus{
          outline: none;
        }

        .input-wrapper{
          display: flex;
          gap: 30px;
          div{
            width: 50%;
            display: flex;
            flex-direction: column;
          }
          // select{
          //   width: 50%;
          // }
        }

        @include mobile{
          .input-wrapper{
            flex-direction: column;
            gap: 10px;
            div{
              width: 100%;
            }
          }
        }

        select {
          border: 1px solid lightgray;
          border-radius: 5px;
          padding: 10px 10px;
          font-size: medium;
          width: 96%;
          background-color: transparent;
        }
        
        button {
          width: 50%;
          padding: 10px;
          border: none;
          background-color: #000;
          color: white;
          font-weight: bold;
          cursor: pointer;
          font-size: large;
          border-radius: 10px;
          margin: auto;

          .loader{
            display: flex;
            justify-content: center;
            align-items: center;
            p{
              color: black;
              font-weight: 700;
              font-size: medium;
              margin-right: 5px;
            }
          }


          .spinner{
            width: 1rem;
            height: 1rem;
            border: 2px solid black;
            border-radius: 50%;
            border-top: none;
            border-right: none;
            animation: spin 0.4s linear infinite ;
          }
        }

        button:hover{
          background-color: #3d858a;
          transition: all 0.5s ease;
        }

        .error {
          color: rgb(255, 51, 51);
          font-size: 14px;
        }
      }
      p {
        // display: none;
        font-size: 0.9rem;
        color: black;
        margin-top: 5px;
        font-weight: 600;
        text-align: center;

        .link-signup{
          color: rgb(201, 195, 195);
          text-decoration: none;
        }


        // @include mobile {
        //   display: block;
        // }
      }
    }
  }
  @include mobile {
    .card{
     .left{
     display: none;
     flex:0;
     }
    }
   }
}

@keyframes bounceO {
  0%, 100% {
    padding: 90px;
  }
  50% {
    padding: 0px;
  }
}

@keyframes bounceM {
  0%, 100% {
    padding: 70px;
  }
  50% {
    padding: 0px;
  }
}

@keyframes bounceI {
  0%, 100% {
    padding: 60px;
  }
  50% {
    padding: 0px;
  }
}
