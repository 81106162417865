@import "../../style.scss";

.navbar {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 50px;
    border-bottom: 1px solid themed("border");
    position: sticky;
    top: 0;
    background-color: themed("bg");
    color: themed("textColor");
    z-index: 999;

    .left {
      display: flex;
      align-items: center;
      gap: 370px;

      .namLogo{
        @include mobile{
          display: none;
        }
      }

      .search {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid themed("border");
        border-radius: 5px;
        padding: 5px;
        position: relative;
        width: 30vw;

        input {
          border: none;
          width: 500px;
          background-color: transparent;
          color: themed("textColor");
          outline: none;

          @include mobile {
            display: none;
          }

          @include tablet {
            width: 200px;
          }
        }

        .searchResultsContainer {
          position: absolute;
          top: 100%;
          left: -1px;
          background-color: themed("bgSoft");
          border: 1px solid themed("border");
          border-top: none;
          max-height: 300px;
          overflow-y: auto;
          z-index: 999;
          height: 200px;
          width: 100%;

          li {
            padding: 10px;
            cursor: pointer;

            &:hover {
              background-color: themed("bgHover");
            }
          }
        }

        @include mobile {
          &.resultActive{
          position: fixed;
          // top:70px;
          background-color: themed("bg");
          top: 0px;
          width: 97%;
          right: 0px;
          left: 0px;

          input{
            display: block;
          }
        }
      }
      }

      @include mobile{
        gap: 40px;
      }

      @include tablet{
        gap: 100px;
      }

      @include laptop{
        gap: 70px;
      }


      // .notification {
      //   position: relative;

      //   .badge {
      //     position: absolute;
      //     top: -5px;
      //     right: -5px;
      //     background-color: red;
      //     color: white;
      //     font-size: 12px;
      //     padding: 2px 5px;
      //     border-radius: 50%;
      //   }
      // }

      // .notificationList {
      //   position: absolute;
      //   top: 56px;
      //   width: 300px;
      //   max-height: 40vh;
      //   overflow-y: scroll;
      //   background-color: themed("bg");
      //   // background-color: #ffe0b5;
      //   box-shadow: themed("bgSoft") 5px 5px 50px;
      //   border: 1px solid #ccc;
      //   color: black;
      //   border-radius: 10px;
      //   padding: 20px;
      //   font-weight: 600;
      //   display: none;
      //   z-index: 100;
      //   right: 213px;
      //   color: themed("textColor");
      //   font-size: 0.8rem;

      //   &::-webkit-scrollbar{
      //     display: none;
      //   }

      //   &.visible{
      //     display: block;
      //   }

      //   hr{
      //     margin: 10px;
      //   }

      //   .notificationItem{
      //     margin-bottom: 6px;
      //     color: black;
      //     padding: 10px;
      //     border-radius: 10px;
      //     background-color: rgb(240, 238, 238);
      //   }
        
      //   @include mobile {
      //     width: 200px;
      //     left: 130px;
      //   }
      // }

      // .notificationOverlay {
      //   position: fixed;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background-color: transparent;
      //   z-index: 99;
      //   opacity: 50%;
      // }

      a {
        img {
          height: 70px;
        }
      }

      span {
        font-weight: bold;
        font-size: 20px;
        color: themed("logo");
      }

    }

    .center{
      .search {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid themed("border");
        border-radius: 5px;
        padding: 5px;
        position: relative;

        input {
          border: none;
          width: 500px;
          background-color: transparent;
          color: themed("textColor");
          outline: none;

          @include mobile {
            display: none;
          }

          @include tablet {
            width: 200px;
          }
        }

        .searchResultsContainer {
          position: absolute;
          top: 100%;
          left: -1px;
          background-color: themed("bgSoft");
          border: 1px solid themed("border");
          border-top: none;
          max-height: 300px;
          overflow-y: auto;
          z-index: 999;
          height: 200px;
          width: 100%;

          li {
            padding: 10px;
            cursor: pointer;

            &:hover {
              background-color: themed("bgHover");
            }
          }
        }

        @include mobile {
          &.resultActive{
          position: fixed;
          // top:70px;
          background-color: themed("bg");
          top: 0px;
          width: 97%;
          right: 0px;
          left: 0px;

          input{
            display: block;
          }
        }
      }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      .notification {
        position: relative;

        .badge {
          position: absolute;
          top: -5px;
          right: -5px;
          background-color: red;
          color: white;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 50%;
        }
      }

      .notificationList {
        position: absolute;
        top: 56px;
        width: 300px;
        max-height: 40vh;
        overflow-y: scroll;
        background-color: themed("bg");
        // background-color: #ffe0b5;
        box-shadow: themed("bgSoft") 5px 5px 50px;
        border: 1px solid #ccc;
        color: black;
        border-radius: 10px;
        padding: 20px;
        font-weight: 600;
        display: none;
        z-index: 100;
        right: 100px;
        color: themed("textColor");
        font-size: 0.8rem;

        &::-webkit-scrollbar{
          display: none;
        }

        &.visible{
          display: block;
        }

        hr{
          margin: 10px;
        }

        .notificationItem{
          margin-bottom: 6px;
          color: black;
          padding: 10px;
          border-radius: 10px;
          background-color: rgb(240, 238, 238);
        }
        
        @include mobile {
          width: 200px;
          left: 130px;
        }
      }

      .notificationOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 99;
        opacity: 50%;
      }

      // button{
      //   width: 4rem;
      //   height: 2rem;
      //   font-weight: 600;
      //   font-size: 0.9rem;
      // }

      // button:hover{
      //   background-color: rgb(241, 58, 58);
      // }

      @include mobile {
        // display: none;
      }

      .user {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;

        @include tablet {
          display: none;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-style: inherit;
          color: themed("textColor");

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            margin-left: 10px;
            @include profile-name;
          }
        }
      }

      // button {
      //   @include button-primary;
      //   // background-color: #e4002b;
      //   color: white;
      //   padding: 6px;

      //   @include mobile {
      //     display: none;
      //   }
      // }
    }
    @include mobile {
    &.messagePage{
      display: none;
    }
  }
  }
}