@import "../../style.scss";

.createEvents {
  @include themify($themes) {
    background-color: themed("bgSoft");
    color: themed("textColor");
    height: calc(100vh - 50px);
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      margin-top: 50px;
    }

    .formGroups {
      margin: 50px;
      width: 500px;
      // width: 75vw;
      padding: 20px;
      border-radius: 10px;
      background-color: themed("bg");
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;

      @include mobile {
        margin: 0px;
        width: 80%;
      }

      .inp {
        border: 1px solid themed("border");
        border-radius: 5px;
        outline: none;
        padding: 5px;
        font-size: 1rem;
        font-weight: 600;
        width: 100%;
        background-color: themed("bgSoft");
        color: themed("textColor");
        @include mobile {
          width: 90%;
          padding: 20px;
        }
      }

      .selInp {
        border: 1px solid themed("border");
        border-radius: 5px;
        outline: none;
        padding: 5px;
        font-size: 1rem;
        font-weight: 600;
        width: 100%;
        background-color: themed("bgSoft");
        color: themed("textColor");
        @include mobile {
          width: 75%;
          padding: 20px;
        }
      }

      .inpFile{
        width: 50%;
        padding: 30px;
        @include mobile{
          padding:'10px'
        }
      }

      .stDate{
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        gap: 100px;
        align-items: center;
        @include mobile{
          flex-direction: column;
          width: 100%;
          gap: 10px;
        }
        .sDate{
            width: 100%;
            .labelForE{
                font-size: 14px;
                font-weight: 600;
            }
        }
      }

      button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: themed("primary-color");
        color: themed("bg");
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
}
