@import "../../style.scss";



.profile {
  @include themify($themes) {
    background-color: themed("bgSoft");

    .images {
      width: 100%;
      height: 300px;
      position: relative;

      .cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .profilePic {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 200px;
      }
    }
    .profileContainer {
      padding: 20px 70px;

      @include mobile{
        padding: 10px;
      }

      @include tablet{
        padding: 20px;
      }

      .uInfo {
        height: 150px;
        -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        border-radius: 20px;
        background-color: themed("bg");
        color: themed("textColor");
        padding: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @include mobile{
          flex-direction: column;
          height: 30vh;
          padding: 20px;
          margin-top: 100px;
        }

        @include tablet{
          flex-direction: column;
          height: 30vh;
          padding: 20px;
          margin-top: 100px;
        }

        .left {
          flex: 1;
          display: flex;
          gap: 10px;

          @include tablet{
            flex-wrap: wrap;
          }

          a{
            color: themed("textColorSoft");
          }
        }

        .left1{
          width: 50%;
          display: flex;
          justify-content: center;
          .profilePic {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 20px;
            // position: absolute;
            // left: 0;
            // right: 0;
            // margin: auto;
            // top: 200px;
          }
        }

        .center {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          span {
            font-size: 2rem;
            font-weight: 500;
          }
          @include mobile{
            span{
              font-size: 1rem;
            }
          }
          p{
            font-size: 13px;
            // color: gray;
          }

          .info {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .item {
              display: flex;
              align-items: center;
              gap: 5px;
              color: themed("textColorSoft");

              span {
                font-size: 0.8rem;
                font-weight: 600;
              }
            }
          }
          button {
            border: none;
            background-color: themed("primary-color");
            color: themed("text-color");
            font-weight: 600;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
          }

          button:hover{
            background-color: themed("primary-color-hover");
          }
        }

        .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          margin-top: 10px;
        }
      }
      .profileTab{
        height: 100px;
        -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        border-radius: 20px;
        background-color: themed("bg");
        // color: themed("textColor");
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 98%;
        margin-bottom: 20px;

        .tabs{
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          height: 100%;

          .line{
            height: 100%;
            width: 0%;
            border: 1px solid rgb(172, 171, 171);
          }

          button{
            width: 100%;
            background-color: transparent;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // color: themed("text-color-blur");
            color: gray;
            cursor: pointer;
            font-size: 16px;
            margin: 0 10px;
            padding: 5px;
            text-decoration: none; 
            font-weight: 700; 
            // border-right: 1px solid gray;
            

            &.active {
              color: themed("textColor");
              font-size: large;
            }

          }
        }
      }
    }
  }
}
