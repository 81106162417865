@import "../../style.scss";

.createComp {
  @include themify($themes) {
    background-color: themed("bgSoft");
    color: themed("textColor");
    height: calc(100vh - 50px);
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;

    h1 {
      margin: 20px;
      @include mobile{
        margin: 10px;
      }
    }

    form{
        padding: 30px;
        background-color: themed("bg");
        width: 20vw;
        border-radius: 10px;
        overflow-y: scroll;
        &::-webkit-scrollbar{
          display: none;
        }
        @include mobile{
            width:90%
        }

    .date-grp{
      display: flex;
      justify-content: space-between;
      @include mobile{
        flex-direction: column;
      }
    }    
    .time-grp{
      display: flex;
      justify-content: space-between;
      @include mobile{
        flex-direction: column;
      }
    }   
    .file-grp{
      display: flex;
      justify-content: space-between;
      margin: 10px;
      align-items: center;
      height: 100px;
    } 

    .form-group{
        display: flex;
        flex-direction: column;
        margin: 15px;
        label{
          font-weight: 550;
        }
        input,textarea{
            width: 95%;
            padding: 10px;
            border-radius: 7px;
            resize: none;
            outline: none;
            border: 1px solid gray;
        }
    }

    button {
        width: 100%;
      margin-top: 20px;
      padding: 10px 20px;
      background-color: themed("primary-color");
      color: themed("text-color");
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 700;
    }
}
  }
}

.orgAdd{
  @include themify($themes) {
    background-color: themed("bgSoft");
    color: themed("textColor");
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // padding: 30px;
    gap: 20px;
    button {
      // width: 10%;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: themed("primary-color");
    color: themed("text-color");
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
  }
  }
}
