@import "../../style.scss";

.bottomNavContainer {
  @include themify($themes) {
    display: none;

    @include mobile{
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0px;
    background-color: themed("bg");
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &.messagePage{
      display: none;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .bottomNavitem {
        a {
          display: flex;
          align-items: center;
          gap: 10px;
          text-decoration: none;
          color: themed("textColor");

          img {
            width: 30px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
}

.drawer-container{
  background-color: black;

  .userDetails{
    .profile{
      color: black;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      text-decoration: none;
      border: none;
      padding: 10px;
      background-color: white;

      .name{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        font-size: 0.8rem;
        h5{
          color: gray;
        }
      }
    }
    img{
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .hr{
    margin: 10px;
    border: lightgray 1px solid;
  }
  .logout{
    background-color: #e4002b;
    margin-top: 30px;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    font-weight: 700;
  }
}

.options{
  display: flex;
  gap: 10px;
  flex-direction: column;
  a{
    text-decoration: none;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;
    color: black;
    font-weight: 700;
  }
}

.leftBar.messagepage {
  flex: 0;
  background-color: black;
}
