
.resetPassword-container{
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    box-sizing: border-box;
    .main-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border: 1px solid #ccc;
        padding: 30px;
        width: 350px;
        margin-bottom: 10vh;
    .img-container{
        width: 110px;
        height: 130px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    .form-container{
        width: 100%;
        form{
        display: flex;
            flex-direction: column;
            gap: 20px;
        div{
            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                &.r-error{
                    color: rgb(209, 59, 59);
                    font-size: 12px;
                    padding: 0;
                    margin: 0;
                    line-height: 0;
                    margin-top: 4px;
                }
            }
        }

        input{
          border: 1px solid lightgray;
          padding: 10px 10px;
          border-radius: 5px;
        }

        .f-button {
            padding: 10px;
          border: none;
          
          color: white;
          font-weight: bold;
          cursor: pointer;
          border-radius: 5px;

          &.sent-email{
            background-color: #938eef;
          }
          &.success-email{
            background-color: rgb(78, 180, 78);
          }
        }
    }
}
}
}

@media screen and (max-width: 600px) {
    .resetPassword-container {
      .main-container {
        border: none;
        padding: 30px;
        width: 100%;
        max-width: 350px;
      }
    }
  }