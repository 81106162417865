@import "../../style.scss";

.singleEventPage {
    @include themify($themes) {
        background-color: themed('bg');
        color: themed('textColor');
        // padding: 20px;
        height: calc(100vh - 110px);
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .eveCover {
            width: 100%;
            height: 50vh;
            object-fit: cover;
        }

        .date {
            width: 150px;
            height: auto;
            border-radius: 20px;
            padding: 0;
            border-radius: 0 0 20px 20px;
            position: relative;
            top: -92px;
            left: 20px;

            .headDate {
                height: 50px;
                background-color: red;
                border-radius: 20px 20px 0 0;
            }

            .btDate {
                text-align: center;
                padding: 30px;
                font-size: 30px;
                font-weight: 600;
                color: themed("textColor");
                box-shadow: 1px 1px 10px lightgray;
                border-radius: 0 0 20px 20px;
                background-color: themed('bg');
            }
        }

        .eventDetails {
            margin-top: 50px;
            width: 50%;
            margin-right: auto;
            box-shadow: 1px 1px 10px lightgray;
            margin-left: auto;
            border-radius: 10px;
            background-color: themed("bg");
            padding: 20px;
            margin-bottom: 50px;

            .catName {
                background-color: themed("primary-color");
                color: themed("text-color");
                padding: 5px;
                border-radius: 5px;
            }

            .detDesc {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                font-weight: 600;
                font-size: 14px;
            }

            .userLink {
                text-decoration: none;
                font-weight: 700;
                font-size: large;
                color: themed("textColor");
            }
        }
    }
}