@import "../../style.scss";

.eventsPage{
    @include themify($themes) {
        width: 60vw;
        border: none;
        background-color: themed('bgSoft');
        // background-color: red;
        color: themed('textColor');
        padding: 20px;
        height: calc(100vh - 110px);
        overflow:auto;
        &::-webkit-scrollbar {
            display: none;
        }
        
        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            button{
                margin-top: 20px;
                padding: 10px 20px;
                background-color: themed("primary-color");
                color: themed("bg");
                border: none;
                border-radius: 5px;
                cursor: pointer;
                
                a{
                    color: themed("bg");
                    text-decoration: none;
                    font-size: 1rem;
                    font-weight: 700;
                }
            }
        }
        .events{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            place-items: center;
            margin-top: 20px;

            .event{
                box-shadow: 0px 0px 5px lightgray;
                border-radius: 10px;
                height:max-content;  
                span{
                    color: red;
                    font-weight: 600;
                    font-size: small;
                }
            }

        }
}
}