@import "../../style.scss";

.about {
  @include themify($themes) {
    background-color: themed("bgSoft");
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    padding: 50px;
    margin-top: 10px;
    .user-details{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        margin: 30px;
        .item{
            h4{
                color: gray;
            }
            p{
                color:themed("textColor");
                font-weight: 600;
            }
        }
    }
    
  }
}
