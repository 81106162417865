@import "../../style.scss";


a{
  text-decoration: none;
}

.searchItem {

  @include themify($themes) {
  
    
    
    @include tablet{
      padding: 20px;
    }
    
    background-color: themed('bg');
    color:themed("textColor");
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 7px 0px 0px 0px;
    padding: 0px 10px;
  
    @include mobile {
      padding: 10px;
    }
    
   cursor: pointer;
      

    .profilePic {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  
    .info {
      span {
        font-weight: 700;
        font-size: 0.9rem;
        text-decoration: none;
      }
  
      p {
        font-size: 12px;
        
      }
    }

    
  }
  }