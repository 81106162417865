@import "../../style.scss";

.noPages{
    @include themify($themes) {
        background-color: themed("bgSoft");
        color: themed("textColor");
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: calc(100vh - 110px);
        overflow:auto;
        &::-webkit-scrollbar {
            display: none;
        }

        div{
            display: flex;
            gap: 10px;
        }

        h1 {
            text-align: center;
            margin-bottom: 20px;
        }
        button{
            margin-top: 20px;
            padding: 10px 20px;
            background-color: themed("primary-color");
            color: themed("textColor");
            border: none;
            border-radius: 5px;
            cursor: pointer;
            
            a{
                color: themed("text-color");
                text-decoration: none;
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
}

.selectUserPage{
    @include themify($themes) {
        width: 100%;
        border: none;
        background-color: themed('bgSoft');
        // background-color: red;
        color: themed('textColor');
        padding: 20px;
        height: calc(100vh - 110px);
        overflow:auto;
        &::-webkit-scrollbar {
            display: none;
        }

        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            button{
                margin-right: 20px;
                padding: 10px 20px;
                background-color: themed("primary-color");
                color: themed("textColor");
                border: none;
                border-radius: 5px;
                cursor: pointer;
                
                    a{
                        color: themed("text-color");
                        text-decoration: none;
                        font-size: 0.8rem;
                        font-weight: 700;
                    }
                }
        }

        .userPage{
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            @include mobile{
                grid-template-columns: 1fr 1fr;
                gap: 5px;
            }
        } 

        .createAgain{
            margin-top: 50px;
            display: flex;
            justify-content: center;
            
            button{
                margin-top: 20px;
                padding: 10px 20px;
                background-color: themed("primary-color");
                color: themed("textColor");
                border: none;
                border-radius: 5px;
                cursor: pointer;
                width: 25%;
            
                a{
                    color: black;
                    text-decoration: none;
                    font-size: 1rem;
                    font-weight: 700;
                }
            }
        }
    }
}