@import "../../style.scss";

.profile {
  @include themify($themes) {
    background-color: themed("bgSoft");

    .about {
      height: max-content;
      -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      border-radius: 20px;
      background-color: themed("bg");
      color: themed("textColor");
      padding: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h1{
        text-align: center;
        margin-bottom: 10px;
      }

      h4{
        font-weight: 700;
        color: gray;
        margin-bottom: 5px;
      }

      hr {
        margin: 20px 0px;
        border: 0.2px  solid rgb(204, 201, 201);
        height: 0.2px;
        background-color: themed("border");
        width: 100% ;
      }

      p{
        font-weight: 600;
      }
      .item{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 50%;
        text-align: start;
        padding: 15px;
      }
      @include mobile{
        width: 100%;
      }
    
      .user-details{
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;  
     }
     @include mobile{
        .user-details{
            flex-direction: column;
        }
     }
    }
  }
}
