.vendor-status-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
  
    .chart-section {
      margin-top: 65px;
      width: 60%;
    }
  
    .filter-section {
      width: 50%;
  
      h3 {
        margin-bottom: 10px;
      }
  
      .filter {
        margin-bottom: 20px;
  
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
  
      .status-table {
        width: 100%;
        border-collapse: collapse;
        background-color: azure;
  
        th, td {
          padding: 10px;
          text-align: left;
          border: 1px solid #ddd;
        }
      }
    }
  }
  