@import "../../style.scss";

.noGroups{
    @include themify($themes) {
        background-color: themed("bgSoft");
        color: themed("textColor");
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: calc(100vh - 110px);
        overflow:auto;
        &::-webkit-scrollbar {
            display: none;
        }

        h1 {
            text-align: center;
            margin-bottom: 20px;
        }
        button{
            margin-top: 20px;
            padding: 10px 20px;
            background-color: themed("primary-color");
            color: themed("textColor");
            border: none;
            border-radius: 5px;
            cursor: pointer;
            
            a{
                color: themed("text-color");
                text-decoration: none;
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
}

.selectGroups{
    @include themify($themes) {
        width: 100%;
        padding: 10px;
        border: none;
        background-color: themed('bgSoft');
        // background-color: red;
        color: themed('textColor');
        padding: 20px;
        height: calc(100vh - 110px);
        overflow:auto;
        &::-webkit-scrollbar {
            display: none;
        }

        .loading{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            p{
                font-weight: 600;
                font-size: 2rem;
                display: flex;
                align-items: center;
                div{
                    width: 1rem;
                    height: 1rem;
                    // background-color: red;
                    border-right: 2px solid black;
                    border-bottom: 2px solid black;
                    border-radius: 50%;
                    animation: spin 0.8s linear infinite ;
                    @keyframes spin {
                        from{
                            transform: rotate(0deg);;
                        }
                        to{
                            transform: rotate(360deg);;
                        }
                    }
                }
            }
        }

        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            button{
                margin-right: 20px;
                padding: 10px 20px;
                background-color: themed("primary-color");
                color: themed("textColor");
                border: none;
                border-radius: 5px;
                cursor: pointer;
                
                    a{
                        color: themed("text-color");
                        text-decoration: none;
                        font-size: 0.8rem;
                        font-weight: 700;
                    }
                }
        }

        .usergroups{
            margin-top: 30px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            cursor: pointer;
            @include mobile{
                grid-template-columns: 1fr;
            }

           .group-container{
            display: flex;
            justify-content: space-between;
            background-color: themed("bg");
            text-decoration: none;
            // padding: 10px;
            border-radius: 10px;
            width: 90%;
            height: 100px;
            display: flex;
            gap: 20px;
            cursor: pointer;
            // -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            // -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            // box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 5px 15px themed("border");

            .user{
                display: flex;
                gap: 10px;
                .left{
                    img{
                        width: 150px;
                        height: 100px;
                        border-radius: 10px  0 0 10px;
                        object-fit: cover;
                    }
                }
                .right{
                    width: 80%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    h4{
                        color: themed("textColor");
                    }
                    h5{
                        color: themed("textColorSoft");
                    }
                    h6{
                        color: themed("textColor");
                    }
                }
            }
            .deleteGrp{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px;
                
                button{
                    border:none;
                    background-color: themed("bg");
                    cursor: pointer;
                }
            }
           }
        } 

        
    }
}