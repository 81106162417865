@import "../../style.scss";

.leftBar {
  @include themify($themes) {
    flex: 1.5;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: scroll;
    background-color: themed("bg");
    color: themed("textColor");
    max-width: 255px;
    min-width: 200px;
    
    &.messagePage{
      flex:none;
    }

    @include mobile{
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      padding: 20px;
a{
  text-decoration: none;
}
      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }
      .hr-top{
        margin: 0;
        border: none;
        height: 0.5px;
        background-color: themed("border");
        width: 100%;
      }

      .menu {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &.messagePage{
        align-items: center;
        align-items: center;
        }
        span {
          font-size: 12px;
        }

        .user { 
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }

          div{
            &.messagePage{
              display: none;
            }
          span {
            color: themed("textColor");
            font-weight: 700;
            @include profile-name;
          }

          p{
            font-size: 11px;
            font-weight: 600;
            margin-top: 5px;
            color: gray;
          }
        }
        }

        .item {
         
          a {
            display: flex;
            align-items: center;
            gap: 15px;
            text-decoration: none;
            color: themed("textColor");
            // font-weight: 900;
            
            &.active{
              color: black;
            }

          img {
            width: 25px;
          }

          span {
            // font-size: 2rem;
            @include font-side-heading;
            font-weight: 700;
            &.messagePage{
              display: none;
            }
          }

        }

        &.active{
          background-color: themed("primary-color");
          // color: white;
          // background-color: red ;
          padding: 10px;
          border-radius: 10px;
          
          .icon{
            color: themed("text-color");
          }

          span{
            color: themed("text-color");
            font-weight: 600;
          }
        }
        }
      }
    }

    .bottom{
      // background-color: red;
      box-shadow: 1px 1px 10px themed("border");
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }
}

.leftBar.messagepage {
  flex: 0;
  background-color: black;
}
