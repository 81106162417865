@import "../../../adminStyle.scss";

.adminNavbar {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 50px;
    border-bottom: 1px solid themed("border");
    position: sticky;
    top: 0;
    background-color: themed("bg");
    color: themed("textColor");
    z-index: 999;

    .left {
      display: flex;
      align-items: center;
      gap: 30px;

      a {
        img{
          height: 70px;
        }
      }
      span {
        font-weight: bold;
        font-size: 20px;
        color: themed("logo");
      }

      .search {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid themed("border");
        border-radius: 5px;
        padding: 5px;
        position: relative;

        input {
          border: none;
          width: 500px;
          background-color: transparent;
          color: themed("textColor");
          outline: none;

          @include mobile {
            display: none;
          }

          @include tablet {
            width: 200px;
          }
        }

        .searchResultsContainer{
          position: absolute;
          top: 100%;
          left: -1px;
          background-color: themed("bgSoft");
          border: 1px solid themed("border");
          border-top: none;
          max-height: 300px;
          overflow-y: auto;
          z-index: 999;
          height: 200px;
          width: 100%;

          li {
            padding: 10px;
            cursor: pointer;

            &:hover {
              background-color: themed("bgHover");
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      @include mobile {
        // display: none;
      }

      .user {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        font-weight: 500;

        @include tablet {
          display: none;
        }

        .user-profile {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-style: inherit;
          color:themed("textColor");        
         

          
        }
        button {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        span {
          margin-left: 10px;
          @include profile-name;
        }

        .dropdown-menu {
          position: absolute;
          top: 100%;
          right: 0;
          background-color: #fff;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          list-style: none;
          margin: 0;
          padding: 10px 0;
          width: 200px;
          z-index: 1000;
          display: none;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease;
        
          &.show {
            display: block;
            opacity: 1;
            visibility: visible;
          }
        
          li {
            padding: 8px 16px;
        
            a {
              text-decoration: none;
              color: #333;
              display: block;
              font-size: 14px;
              font-weight: 500;
              padding: 6px 0;
              transition: background-color 0.2s ease;
        
              &:hover {
                background-color: #f1f1f1;
              }
            }
          }
        }

        
      }


      // .user {
      //   position: relative;
      // }
      
      // .user-profile {
      //   display: flex;
      //   align-items: center;
      //   background: none;
      //   border: none;
      //   cursor: pointer;
      //   padding: 8px 12px;
      //   border-radius: 4px;
      //   transition: background-color 0.3s ease;
      
      //   &:hover {
      //     background-color: #f5f5f5;
      //   }
      
      //   .profile-img {
      //     width: 30px;
      //     height: 30px;
      //     border-radius: 50%;
      //     margin-right: 8px;
      //   }
      
      //   span {
      //     font-size: 14px;
      //     font-weight: 600;
      //     color: #333;
      //   }
      // }
      
      // .dropdown-menu {
      //   position: absolute;
      //   top: 100%;
      //   right: 0;
      //   background-color: #fff;
      //   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      //   border-radius: 6px;
      //   list-style: none;
      //   margin: 0;
      //   padding: 10px 0;
      //   width: 200px;
      //   z-index: 1000;
      //   display: none;
      //   opacity: 0;
      //   visibility: hidden;
      //   transition: opacity 0.3s ease, visibility 0.3s ease;
      
      //   &.show {
      //     display: block;
      //     opacity: 1;
      //     visibility: visible;
      //   }
      
      //   li {
      //     padding: 8px 16px;
      
      //     a {
      //       text-decoration: none;
      //       color: #333;
      //       display: block;
      //       font-size: 14px;
      //       font-weight: 500;
      //       padding: 6px 0;
      //       transition: background-color 0.2s ease;
      
      //       &:hover {
      //         background-color: #f1f1f1;
      //       }
      //     }
      //   }
      // }
      

      button {
      @include button-primary;
      // background-color:#e4002b;
      color: white;
      padding: 6px;
      }
    }
  }
}