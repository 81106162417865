@import "../../style.scss";


.shareIconGrp{
    width:"40px";
    height:"40px"
}

.members {
  @include themify($themes) {
    padding: 50px;

    .noMem{
        padding: 30px;
        color: themed("textColor");
    }

    .member{
        background-color: themed("bg");
        padding: 20px;
        margin: 10px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right{
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 20px;
            h3{
                color: themed('textColor');
                cursor: pointer;
                &:hover{
             
                    color: themed("primary-color-hover");
                }
            }
            img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
            a{
                color: themed('textColor');
            }
        }
        .left{
            .remove{
                background-color: themed("primary-color");
                padding: 5px;
                border: none;
                border-radius: 5px;
                font-weight: 700;
                
            }
        }
        .removeParticipant{
            .actionBtn {
                background-color: themed("primary-color");
                color: themed('text-color');                
                border-radius: 5px;
                height: 35px;
                margin-right: 20px;
                display: flex;
                padding-right: 5px;
                padding-left: 5px;
                display: flex;                    
                align-items: center;
                color: red;
            }
        }
    }
  }
}