@import "../../adminStyle.scss";

.adminUserManager {
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh -    100px);
    background-color: themed("bgSoft");
    color: themed("textColor");

    @include mobile {
      padding: 10px;
      min-height: calc(100vh -    100px);
    }

    @include tablet{
      padding: 20px;
    }
    
    .user-list {

      .heading-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #deebfb;

        .image-heading{
          width: 10%;
          h3{
            text-align: center;
          }
        }

        .info-heading{
          width: 80%;
          display: flex;
          justify-content: space-between;

          .info-heading-common {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            h3{
              text-align: center;
            }
          }
        }

        .button-heading{
          width: 10%;
          h3{
            text-align: center;
          }
        }

      }



      display: grid;
      margin: 20px;
      .user-card {
        display: flex;
        align-items: center;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-around;
        background-color: azure;

        .image-container {
          width: 10%;
          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;
          }
  
        }
     
        .user-info {
          width: 80%;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .info-container-common {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
          }
        }

        .button-container {
          width: 10%;
          display: flex;
          justify-content: center;
          button {
            margin-top: 10px;
            padding: 5px 10px;
            background-color: #f44336;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            justify-self: end;
            &:hover {
              background-color: #da190b;
            }
          }
        }

   
      }
    }
    .top-bar-container{
      .menu-left{
        justify-content: end;
        display: flex;
        gap: 10px;


        .search{
          align-items: center;
          border: 1px solid #d3d3d3;
          border-radius: 5px;
          display: flex;
          gap: 10px;
          padding: 5px;          
        }
        .search input {
          background-color: #0000;
          border: none;
          color: #000;
          outline: none;
          width: 300px;
        }
        .submit-btn {
          background-color: #0d62ff;
          border-radius: 10px;
          color: #fff;
          font-size: 1rem;
          margin-left: 5px;
          padding: 6px;
      }
      }
    }
  }
}