@import "../../style.scss";

.friendsListings {
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh - 110px);
    color: themed("textColor");

    .friendsHeadingContainer {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading {
        font-size: 1.8rem;
        font-weight: 700;
      }

      .requests {
        button {
          background-color: transparent;
          border: none;
          color: themed("textColor");
          cursor: pointer;
          font-size: 16px;
          margin: 0 10px;
        }
      }
    }

    .requestModalContainer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
      
      .requestModalWrapper {
        
        margin: auto;
        width: 40%;
        height: 70%;
        background-color: themed("bg");
        padding: 50px;
        z-index: 999;
        display: flex;
        flex-direction: column;
        gap: 20px;
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        position: relative;

        @include mobile {
          width: 100%;
          height: 100%;
        }

        h1 {
          color: lightgrey;

          @include mobile {
            font-size: 20px;
          }

        }

        .requestContainer{
          display: flex;
          justify-content: space-between;
          align-items: center;

          .actions{
            display: flex;
            gap: 10px;
            .accept-btn{
              background-color: rgb(98, 228, 98);
              color: themed("text-color");
              border: none;
              padding: 10px 20px;
              cursor: pointer;
              border-radius: 10px;
              font-weight: 600;
              font-size: 0.9rem;
            }

            @include mobile{
              .accept-btn{
                padding: 10px;
              }
            }

            .reject-btn{
              background-color: #f8423b;
              color: themed("text-color");
              border: none;
              padding: 10px 20px;
              cursor: pointer;
              border-radius: 10px;
              font-weight: 600;
              font-size: 0.9rem;
            }
            @include  mobile{
              .reject-btn{
                padding: 10px;
              }
            }
          }

          
        }

        .close {
          position: absolute;
          top: 20px;
          right: 20px;
          border: none;
          background-color: #f72922;
          padding: 5px;
          border-radius: 5px;
          cursor: pointer;
          color: white;
        }
      }
    }

    @include mobile {
      padding: 10px;
      min-height: calc(100vh - 160px);
    }

    @include tablet {
      padding: 20px;
    }
  }
}