@import "../../../adminStyle.scss";

.adminLeftBar {
  @include themify($themes) {
    flex: 1;
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: scroll;
    background-color: themed("bg");
    color: themed("textColor");
    min-width: 250px;
    
    &.messagePage{
      flex:none;
    }

    @include mobile{
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      padding: 20px;
a{
  text-decoration: none;
}
      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }
      .hr-top{
        margin: 0;
        border: none;
        height: 0.5px;
        background-color: themed("border");
        width: 100%;
      }

      .menu {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &.messagePage{
        align-items: center;
        align-items: center;
        }
        span {
          font-size: 12px;
        }

        .user { 
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }

          div{
            &.messagePage{
              display: none;
            }
          span {
            color: themed("textColor");
            @include profile-name;
          }

          p{
            font-size: 0.8em;
            margin-top: 5px;
            color: gray;
          }
        }
        }

        .item {
         
          a {
            display: flex;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            color: themed("textColor");

            &.active{
              color: white;
            }

          img {
            width: 30px;
          }

          span {
            // font-size: 14px;
            @include font-side-heading;
            &.messagePage{
              display: none;
            }
          }

        }

        &.active{
          background-color: themed('button-primary-bg');
          padding: 5px;
          border-radius: 3px;
        }
        }
      }
    }
  }
}

.leftBar.messagepage {
  flex: 0;
  background-color: black;
}
