@import "../../style.scss";

.home{
  @include themify($themes) {
    // padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh -    100px);
    display: flex;
    justify-content: center;

   .home-ele-wrapper{
    width: 100%;
   }

    @include mobile {
      padding: 10px;
      min-height: calc(100vh -    100px);
    }

    @include tablet{
      padding: 20px;
    }
  }
 }