@import "../../style.scss";

.stories {
  display: flex;
  gap: 10px;
  height: 200px;
  margin-bottom: 30px;
  max-width: 48vw; // Ensures container doesn’t exceed the screen width
  overflow-x: auto; // Enables horizontal scrolling
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Internet Explorer and Edge
  width: 80%;
  margin: auto;
  margin-top: 10px;

  &::-webkit-scrollbar {
    display: none; // Hides the scrollbar for WebKit browsers
  }

  @include mobile {
    height: 50px;
    margin-bottom: 0;
    // justify-content: space-between;
    max-width: 90vw;
    width: 100%;
  }

  @include tablet {
    height: 80px;
    gap: 20px;
  }

  .story {
    flex: 0 0 120px; // Fixed width for each story
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 180px;
    width: 100px;
    background-color: white;

    @include mobile {
      flex: none;
      width: 50px;
      height: 50px;
      border-radius: 50%;

      .ant-image {
        height: 50px;
        width: 50px;
      }
    }

    .storyContent {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ant-image {
      height: 100%;
      width: 120px;
    }

    .userprfstry {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 10px;

      img {
        border-radius: 50%;
      }
    }

    span {
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: white;
      font-weight: 500;

      @include mobile {
        display: none;
      }
    }

    button {
      position: absolute;
      bottom: 5%;
      left: 40px;
      color: rgb(19, 12, 12);
      background-color: #dedfe4;
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      font-size: 30px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 15px;
        height: 15px;
      }
    }

    &:hover {
      .overlay {
        height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
        cursor: pointer;
      }
    }

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 39.33%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      z-index: 1;
      transition: height 0.2s ease;

      &:hover {
        height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
        cursor: pointer
      }
    }
  }
}