@import "../../adminStyle.scss";

.adminDashboard{
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh -    100px);
    background-color: themed("bgSoft");
    color: themed("textColor");

    @include mobile {
      padding: 10px;
      min-height: calc(100vh -    100px);
    }

    @include tablet{
      padding: 20px;
    }
  }
 }