
.forgotPassword-container{
    
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
  background-color: #387478;
  background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295)),
        url("../../assets/demo3.jpg") center;
      background-size: cover;
  .main-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 30px;
      width: 350px;
      margin-bottom: 10vh;
      background-color: transparent;
      backdrop-filter: blur(250px);
  .img-container{
      width: 150px;
      height: 150px;
      img{
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }

  .f-info{
    color: white;
    text-align: center;
      span {
          font-size: 13px;
          color: whitesmoke;
      }
  }

  .form-container-f{
      width: 80%;
      background-color: transparent;
      color: white;
      form{
      display: flex;
          flex-direction: column;
          gap: 20px;
      div{
          display: flex;
          flex-direction: column;
          gap: 5px;
          label{
            font-weight: 600;
          }
          input{
            // border: none;
            border: 1px solid lightgray;
            padding: 10px 10px;
            font-size: medium;
            background-color: transparent;
            color: white;
          }
          input::placeholder{
            color:white
          }
          input:focus{
            outline: none;
          }

          span{
              &.f-error{
                  color: rgb(209, 59, 59);
                  font-size: 12px;
                  padding: 0;
                  margin: 0;
                  line-height: 0;
                  margin-top: 4px;
              }
          }
        }
        
        input{
        border: 1px solid lightgray;
        padding: 10px 10px;
        border-radius: 5px;
      }
      
      .f-button {
          padding: 10px;
        border: none;
        color: white;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
        
        &.sent-email{
          background-color: #938eef;
        }
        &.success-email{
          background-color: rgb(78, 180, 78);
        }
      }
      .rem-pass{
        font-size: 0.9rem;
        text-decoration: none;
        font-weight: 700;
        color: black;
        a{
          color: gray;
        }
      }
  }
}
}
}

@media screen and (max-width: 600px) {
  .forgotPassword-container {
    .main-container {
      border: none;
      padding: 30px;
      width: 100%;
      max-width: 350px;
    }
  }
}