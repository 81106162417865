@import "../../style.scss";

.pages {
  @include themify($themes) {
    background-color: themed("bgSoft");
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      button {
        margin-right: 20px;
        padding: 10px 20px;
        background-color: themed("primary-color");
        color: themed("textColor");
        border: none;
        border-radius: 5px;
        cursor: pointer;

        a {
          color: themed("text-color");
          text-decoration: none;
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
    }

    position: relative;

    // div {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }

    .pagination-container {
      position: absolute;
      // bottom: 15px;
      width: 100%;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
