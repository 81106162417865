@import "../../style.scss";

.friendItem {
  @include themify($themes) {
    display: flex;
    gap: 20px;
    background-color: themed('bgsoft');
    color: themed('textColor');
    cursor: pointer;
    @include mobile {
      padding: 5px;
      
    }
  
  &.active {
    // background-color: rgb(217, 219, 226);
    background-color: themed('bgActive');
    color: themed('textColor');
  }

    .profilePic {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    
  
    .info {
      span {
        font-weight: 500;
      }
      p{
        font-size: 12px;
      }

  
      p {
        font-size: 14px;
        // color: #666666;
      }
    }
  }
  }